import { useState } from "react";

const PaymentResult = ({ queryParams }) => {
  const [paymentResult, setPaymentResult] = useState({
    resultCode: "",
    resultMsg: "",
    payMethod: "",
    amount: "",
    tid: "",
    ordererName: "",
    goodsName: "",
    cardCompany: "",
    installmentMonths: "",
  });
};

export default PaymentResult;
